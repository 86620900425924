import { canUserAccessFeature } from "../../utils/context";

export default {
  namespaced: true,
  state: {
    appConfig: {},
    upToDateEnabledForUser: false,
    dcVisitNoteUploadEnabled: false
  },
  getters: {
    getAppConfig(state) {
      return state.appConfig;
    },
    upToDateEnabled(state) {
      return state.upToDateEnabledForUser;
    },
    dcVisitNoteUploadEnabled(state) {
      return state.dcVisitNoteUploadEnabled;
    }
  },
  mutations: {
    mutateAppConfig(state, { appConfig, email }) {
      state.appConfig = appConfig;
      const bypassList = appConfig && appConfig.bypassUserList && appConfig.bypassUserList.users;
      state.upToDateEnabledForUser = canUserAccessFeature(
        appConfig && appConfig.upToDateEnabled,
        bypassList,
        email
      );
      state.dcVisitNoteUploadEnabled = canUserAccessFeature(
        appConfig && appConfig.dcVisitNoteUploadEnabled,
        bypassList,
        email
      );
    }
  },
  actions: {
    /** Professional's email used for bypass list eligibility. */
    async updateAppConfig({ commit, rootGetters }, appConfig) {
      const email = rootGetters["currentProfessional/email"];
      commit("mutateAppConfig", { appConfig, email });
    }
  }
};
